import {useEffect} from "react";

export function useDateReload(): void {
  useEffect(() => {
    const initialDay = new Date().getDay();
    const interval = setInterval(() => {
      if (new Date().getDay() !== initialDay) {
        (window as any).larche_reloaded = true;
        window.location.reload();
      }
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, []);
}
