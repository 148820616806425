import {useState} from "react";
import {Friend, Location} from "../../types";
import {useDateReload} from "../../hooks/useDateReload";
import FriendView from "../FriendView";
import LocationSelector from "../LocationSelector";

function App() {
  useDateReload();
  const [data, setData] = useState<[Location, string, Friend[]] | null>(null);
  return data === null ? (
    <LocationSelector onLocationEntered={(location, password, friends) => setData([location, password, friends])} />
  ) : (
    <FriendView location={data[0]} password={data[1]} initialFriends={data[2]} />
  );
}

export default App;
