import {MutableRefObject, useLayoutEffect, useState} from "react";
import {Size} from "../types";

export function useElementSize(ref: MutableRefObject<HTMLElement | null>): Size | null {
  const [size, setSize] = useState<Size | null>(null);

  useLayoutEffect(() => {
    const element = ref.current;
    if (element === null) {
      return;
    }

    const updateSize = () => setSize({
      width: element.offsetWidth,
      height: element.offsetHeight,
    });

    updateSize();
    const resizeObserver = new ResizeObserver(() => updateSize());
    resizeObserver.observe(element);
    return () => resizeObserver.disconnect();
  }, []);

  return size;
}
