import {FormEvent, KeyboardEvent, ReactElement, useEffect, useState} from "react";
import {Button, Input, Loader, Menu, Message} from "semantic-ui-react";
import {Friend, Location} from "../../types";
import "./styles.css";

type Props = {
  onLocationEntered: (location: Location, password: string, friends: Friend[]) => void,
};

type State = {
  password: string,
  locations: Location[] | null;
  activeLocationIndex: number | null;
  isCheckingPassword: boolean;
  wrongPassword: boolean;
  error: ReactElement | null;
};

function LocationSelector({onLocationEntered}: Props) {
  const [state, setState] = useState<State>({
    password: '',
    locations: null,
    activeLocationIndex: null,
    isCheckingPassword: false,
    wrongPassword: false,
    error: null,
  });

  useEffect(() => {
    (async () => {
      let locations: Location[] = [];
      let withError = false;
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + '/locations/');
        if (response.status === 200) {
          locations = await response.json();
        } else {
          withError = true;
        }
      } catch (e) {
        withError = true;
      }
      if (withError) {
        setState((state) => ({
          ...state,
          error: (
            <>
              <p>Не вдалося з’єднатися з сервером.</p>
              <Button onClick={() => window.location.reload()}>Перезавантажити сторінку</Button>
            </>
          ),
        }))
      } else {
        setState((state) => ({
          ...state,
          locations,
        }));
      }
    })();
  }, []);

  const onPasswordSubmit = async () => {
    const activeLocation = state.locations![state.activeLocationIndex!];
    const currentPassword = state.password;
    setState((state) => ({
      ...state,
      isCheckingPassword: true,
      wrongPassword: false,
      error: null,
    }));
    let withError = false;
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/locations/' + activeLocation.id + '/?' + new URLSearchParams({
          password: currentPassword,
        })
      );
      switch (response.status) {
        case 200:
          const friends = await response.json();
          onLocationEntered(activeLocation, currentPassword, friends);
          break;
        case 401:
          setState((state) => ({
            ...state,
            isCheckingPassword: false,
            wrongPassword: true,
            error: null,
          }));
          break;
        default:
          withError = true;
          break;
      }
    } catch (e) {
      withError = true;
    }
    if (withError) {
      setState((state) => ({
        ...state,
        isCheckingPassword: false,
        wrongPassword: false,
        error: <p>Не вдалося з’єднатися з сервером</p>,
      }))
    }
  };

  return (
    <div className="locationSelector">
      {state.locations ? (
        <Menu size="massive" compact={true}>
          {state.locations.map(({name, id}, index) => (
            <Menu.Item
              key={id}
              name={name}
              active={index === state.activeLocationIndex}
              onClick={() => setState((state) => ({
                ...state,
                activeLocationIndex: index,
                wrongPassword: false,
                error: null,
              }))}
              disabled={state.isCheckingPassword}
            />))}
        </Menu>
      ) : (
      state.error ? (
        <Message negative={true}>{state.error}</Message>
      ) : (
        <Loader active={true} />
      ))}
      {state.activeLocationIndex !== null && (
        <>
          <div className="locationSelector_auth">
            <Input
              placeholder="Пароль"
              value={state.password}
              type="password"
              autoFocus={true}
              onKeyDown={(e: KeyboardEvent) => {
                if (e.key === 'Enter' && state.password !== '') {
                  onPasswordSubmit().then();
                }
              }}
              action={{
                content: ">",
                loading: state.isCheckingPassword,
                disabled: state.isCheckingPassword || state.password === '',
                onClick: () => onPasswordSubmit(),
              }}
              disabled={state.isCheckingPassword}
              error={state.wrongPassword}
              onInput={(e: FormEvent<HTMLInputElement>) => setState((state) => ({
                ...state,
                password: (e.target as HTMLInputElement).value,
                wrongPassword: false,
                error: null,
              }))}
            />
          </div>
          {state.error && <Message negative={true}>{state.error}</Message>}
        </>
      )}
    </div>
  );
}

export default LocationSelector;
