import {useCallback, useEffect, useRef, useState} from "react";
import {Icon, Message, Segment} from "semantic-ui-react";
import {DateInput} from "semantic-ui-calendar-react";
import moment, {Moment} from "moment";
import "moment/locale/uk";
import {Friend, Location, Size} from "../../types";
import {LoadingState} from "../../hooks/useApi";
import {useElementSize} from "../../hooks/useElementSize";
import {useInterval} from "../../hooks/useInterval";
import {useUpdateStatus} from "../../hooks/useUpdateStatus";
import "./styles.css";

const MAX_STATUS = 3;
const RELOAD_SECONDS = 60;

const DAYS_TO_CHECK = 7;
const HIGHLIGHTED_DAYS_COLOR = 'orange';
const NO_HIGHLIGHTED_DAYS_COLOR = 'green';

const DATE_FORMAT = 'DD-MM-YYYY';

type Props = {
  location: Location,
  password: string,
  initialFriends: Friend[],
};

type LayoutParams = {
  rowCount: number,
  squareSize: number,
};

type Coords = {
  left: number,
  top: number,
};

function getSquareSize(containerSize: Size, squareCount: number, rowCount: number): number {
  const squaresPerRow = Math.ceil(squareCount / rowCount);
  return Math.floor(Math.min(containerSize.width / squaresPerRow, containerSize.height / rowCount));
}

function getOptimalLayoutParams(containerSize: Size, squareCount: number): LayoutParams {
  let optimalLayout: LayoutParams = {
    rowCount: 1,
    squareSize: getSquareSize(containerSize, squareCount, 1),
  };
  for (let rowCount = 2; rowCount <= squareCount; rowCount += 1) {
    const squareSize = getSquareSize(containerSize, squareCount, rowCount);
    if (squareSize > optimalLayout.squareSize) {
      optimalLayout = {
        rowCount,
        squareSize,
      };
    }
  }
  return optimalLayout;
}

function calculateLayout(containerSize: Size, squareCount: number, params: LayoutParams): Coords[] {
  const yMargin = Math.floor((containerSize.height - params.rowCount * params.squareSize) / 2);
  const squaresPerRow = Math.ceil(squareCount / params.rowCount);
  let squaresLeft = squareCount;
  const coords: Coords[] = [];
  for (let row = 0; row < params.rowCount; row++) {
    const squaresInRow = Math.min(squaresLeft, squaresPerRow);
    const xMargin = Math.floor((containerSize.width - squaresInRow * params.squareSize) / 2);
    for (let square = 0; square < squaresInRow; square++) {
      coords.push({
        left: xMargin + square * params.squareSize,
        top: yMargin + row * params.squareSize,
      });
    }
    squaresLeft -= squaresInRow;
  }
  return coords;
}

function getNextStatus(status: number): number {
  const nextStatus = status + 1;
  return nextStatus <= MAX_STATUS ? nextStatus : 0;
}

function constructParams(password: string, date: Moment): URLSearchParams {
  return new URLSearchParams({
    password,
    year: date.year().toString(),
    month: (date.month() + 1).toString(),
    day: date.date().toString(),
  });
}

function hasBothZeroAndNonZeroStatus(friends: Friend[]): boolean {
  return friends.some((friend) => friend.status === 0) && friends.some((friend) => friend.status !== 0);
}

function FriendView({location, password, initialFriends}: Props) {
  const dateRequestUrl = (date: Moment) =>
    `${process.env.REACT_APP_API_URL}/locations/${location.id}/?${constructParams(password, date)}`;

  const dateToString = (date: Moment) => date.format(DATE_FORMAT);

  const [today] = useState(() => moment());
  const [friendsByDate, setFriendsByDate] = useState<{[date: string]: Friend[]}>({
    [dateToString(today)]: initialFriends,
  });
  const updateFriendsByDate = (date: Moment, friends: Friend[] | ((friends: Friend[]) => Friend[])) => {
    setFriendsByDate((friendsByDate) => ({
      ...friendsByDate,
      [dateToString(date)]: Array.isArray(friends) ? friends : friends(friendsByDate[dateToString(date)] ?? []),
    }));
  };
  const [date, setDate] = useState(today);
  const [daysToCheck] = useState(() => {
    const daysToCheck: Moment[] = [];
    for (let i = 1; i <= DAYS_TO_CHECK; i++) {
      daysToCheck.push(today.clone().subtract(i, 'days'));
    }
    return daysToCheck;
  });

  useEffect(() => {
    const loadDate = async (date: Moment) => {
      const response = await fetch(dateRequestUrl(date));
      if (response.ok) {
        const friends = await response.json();
        updateFriendsByDate(date, friends);
      }
    };
    for (const day of daysToCheck) {
      loadDate(day).then();
    }
  }, []);
  const markedDays = daysToCheck.filter((day) => hasBothZeroAndNonZeroStatus(friendsByDate[dateToString(day)] ?? []));

  const friends = friendsByDate[dateToString(date)] ?? [];

  const [dateLoading, setDateLoading] = useState(false);
  const minDate = today.clone().subtract(1, 'months');
  const maxDate = today;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const containerSize = useElementSize(containerRef);
  const [updated, setUpdated] = useState(false);
  const [requestId, setRequestId] = useState(0);
  const request = useUpdateStatus(friends, location, constructParams(password, date), updated ? requestId : null);
  const isRequestPending = () => [LoadingState.Loading, LoadingState.Error].includes(request.loadingState);
  useEffect(() => {
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      if (!(window as any).larche_reloaded && isRequestPending()) {
        event.preventDefault();
        return event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => window.removeEventListener('beforeunload', onBeforeUnload);
  }, [request.loadingState]);
  const reloadIfRequired = useCallback(() => {
    if (request.loadingState === LoadingState.Error) {
      setRequestId((requestId) => requestId + 1);
    }
  }, [request.loadingState]);
  useInterval(reloadIfRequired, RELOAD_SECONDS * 1000);
  const optimalLayoutParams = containerSize ? getOptimalLayoutParams(containerSize, friends.length) : null;
  const layout = containerSize && optimalLayoutParams
    ? calculateLayout(containerSize, friends.length, optimalLayoutParams)
    : null;
  return (
    <div className="friendView">
      <div className="friendView_calendar">
        <Segment
          className="friendView_calendarBackground"
          basic
          color={markedDays.length > 0 ? HIGHLIGHTED_DAYS_COLOR : NO_HIGHLIGHTED_DAYS_COLOR}
          inverted
        />
        <DateInput
          value={date.format(DATE_FORMAT)}
          minDate={minDate}
          maxDate={maxDate}
          marked={markedDays}
          markColor={HIGHLIGHTED_DAYS_COLOR}
          onChange={async (event, {value}) => {
            const newDate = moment(value, DATE_FORMAT);
            if (newDate.isValid() && newDate >= minDate && newDate <= maxDate) {
              setDateLoading(true);
              let withError = false;
              try {
                const response = await fetch(dateRequestUrl(newDate));
                if (response.ok) {
                  const friends = await response.json();
                  updateFriendsByDate(newDate, friends);
                } else {
                  withError = true;
                }
              } catch (e) {
                withError = true;
              }
              if (!withError) {
                setDate(newDate);
              }
              setDateLoading(false);
            }
          }}
          closable={true}
          localization="uk"
          clearable={false}
          hideMobileKeyboard={true}
          readOnly={dateLoading || isRequestPending()}
          popupPosition="bottom center"
        />
      </div>
      <div className={`friendView_friends ${dateLoading ? 'friendView_friends-readOnly' : ''}`} ref={containerRef}>
        {layout && optimalLayoutParams && friends.map((friend, index) => (
          <div
            className={`friendView_friend friendView_friend-status-${friend.status}`}
            style={{
              left: layout[index].left + 'px',
              top: layout[index].top + 'px',
              width: optimalLayoutParams.squareSize + 'px',
              height: optimalLayoutParams.squareSize + 'px',
            }}
            key={index}
            onClick={async () => {
              const newStatus = getNextStatus(friend.status);
              updateFriendsByDate(date, (friends) => friends.map((friend, friendIndex) => ({
                ...friend,
                status: friendIndex === index ? newStatus : friend.status,
              })));
              setUpdated(true);
            }}
          >
            <div
              className="friendView_friendFrame"
              style={{
                backgroundImage: `url("${friend.photo}")`,
              }}
            >
            </div>
          </div>
        ))}
        <div
          className={`friendView_status friendView_status-${request.loadingState}`}
          onClick={reloadIfRequired}
        >
          <Message
            size="mini"
            positive={request.loadingState === LoadingState.Ok || request.loadingState === LoadingState.Idle}
            warning={request.loadingState === LoadingState.Loading}
            negative={request.loadingState === LoadingState.Error}
          >
            <Icon
              fitted
              name={{
                [LoadingState.Ok]: 'check' as const,
                [LoadingState.Idle]: 'check' as const,
                [LoadingState.Loading]: 'circle notched' as const,
                [LoadingState.Error]: 'redo alternate' as const,
              }[request.loadingState]}
              loading={request.loadingState === LoadingState.Loading}
            />
          </Message>
        </div>
      </div>
    </div>
  );
}

export default FriendView;
