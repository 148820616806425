import {Friend, Location} from "../types";
import {ApiState, useApi} from "./useApi";

export function useUpdateStatus(
  friends: Friend[],
  location: Location,
  params: URLSearchParams,
  requestId: number | null,
): ApiState<void> {
  return useApi(requestId === null ? {
    endpoint: null,
    requestId: 0,
  } : {
    endpoint: {
      url: `/locations/${location.id}/?${params}`,
      method: 'POST',
      payload: JSON.stringify({statuses: friends.map(({id, status}) => [id, status])}),
    },
    requestId,
  });
}
