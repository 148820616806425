import {useEffect, useMemo, useState} from "react";

export enum LoadingState {
  Idle = 'idle',
  Loading = 'loading',
  Error = 'error',
  Ok = 'ok',
}

export type ApiRequestData = {
  endpoint: {
    url: string,
    method: 'GET' | 'POST',
    payload?: string,
  } | null,
  requestId: number,
};

export type ApiState<T> = {
  loadingState: LoadingState.Idle | LoadingState.Loading | LoadingState.Error,
  request: ApiRequestData,
} | {
  loadingState: LoadingState.Ok,
  request: ApiRequestData,
  data: T,
};

export function useApi<T>(requestParams: ApiRequestData): ApiState<T> {
  const url = requestParams.endpoint?.url;
  const method = requestParams.endpoint?.method;
  const payload = requestParams.endpoint?.payload;
  const requestId = requestParams.requestId;
  const request = useMemo<ApiRequestData>(() => ({
    endpoint: url === undefined ? null : {
      url,
      method: method!,
      payload,
    },
    requestId,
  }), [url, method, payload, requestId]);

  const constructLoadingState = (): ApiState<T> => ({
    loadingState: LoadingState.Loading,
    request,
  });
  const [state, setState] = useState<ApiState<T>>(constructLoadingState);
  useEffect(() => {
    let active = true;
    let controller: AbortController | null = null;
    (async () => {
      if (request.endpoint === null) {
        setState({
          loadingState: LoadingState.Idle,
          request,
        });
      } else {
        setState({
          loadingState: LoadingState.Loading,
          request,
        });
        try {
          controller = new AbortController();
          const response = await fetch(process.env.REACT_APP_API_URL! + request.endpoint.url, {
            method: request.endpoint.method,
            ...(request.endpoint.payload ? {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              },
              body: request.endpoint.payload,
            } : {}),
            cache: 'no-cache',
            signal: controller.signal,
          });
          if (response.status !== 200) {
            throw new Error(`Server responded with status ${response.status}`);
          }
          const data = await response.json();
          if (active) {
            setState({
              loadingState: LoadingState.Ok,
              request,
              data,
            });
          }
        } catch (e) {
          if (active) {
            setState({
              loadingState: LoadingState.Error,
              request,
            });
          }
        }
      }
    })();
    return () => {
      active = false;
      if (controller) {
        controller.abort();
      }
    };
  }, [request]);
  return state.request === request ? state : constructLoadingState();
}
